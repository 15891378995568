<template>
  <div class="animated fadeIn">
    <b-card title="Event Machine">
      <!-- <div class="table-responsive"> -->
      <b-form @submit.prevent="searchFn">
        <div class="row">
          <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1">
            {{ $t('message.machineId') }} :
            <br />
            <!-- {{selectedMachine}} -->
            <model-list-select :list="machineData" v-model="searchData.id" option-value="machineId" option-text="name"
              placeholder="Select Machine ID">
            </model-list-select>
          </div>

          <!-- <div class="col-sm-6 col-md-6 col-lg-2 my-1">
            {{ $t('message.machineId') }} :
            <br />
            <b-form-input v-model="searchData.s_machineId" />
          </div> -->

          <div class="col-sm-6 col-md-6 col-lg-3 col-xl-2 my-1">
            Event :
            <br />
            <b-form-select v-model="searchData.s_event" :options="eventDefine" text-field="event" value-field="event" />
          </div>
          <div class="col-sm-6 col-md-6 col-lg-3 col-xl-2 my-1">
            Level :
            <br />
            <b-form-select v-model="searchData.s_level" :options="events" text-field="text" value-field="value" />
          </div>
          <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4 my-1">
            {{ $t('message.date') }} :
            <br />
            <!-- <date-picker type="date" v-model="searchData.s_datetime" lang="en" range format="yyyy-MM-dd"></date-picker> -->
            <div class="table-responsive">
              <date-time-picker v-model="searchData.s_datetime" type="date"></date-time-picker>
            </div>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-2 my-1">
            <br />
            <b-btn class="col-sm-12 col-md-4 col-lg-12" variant="primary" type="submit">
              <i class="fa fa-search "></i>
              &nbsp;{{ $t('message.search') }}
            </b-btn>
          </div>
        </div>
      </b-form>
      <!-- </div> -->
      <br />
      <div class="table-responsive">
        <table class="table2">
          <thead>
            <tr class="bg-primary" style="text-align-last: center">
              <th>{{ $t('message.machineId') }}</th>
              <th>Event ID</th>
              <th>Event</th>
              <th>
                <nav style="width: 160px;">{{ $t('message.description') }}</nav>
              </th>
              <th>Level</th>
              <th>{{ $t('message.date') }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody style="text-align: center">
            <template v-for="item in rowData">
              <tr>
                <td>{{ item.machineId }}</td>
                <td>{{ item.eventId }}</td>
                <td>{{ item.event }}</td>
                <td>{{ item.remark }}</td>
                <template v-if="item.level === 'WARN'">
                  <td>
                    <span class="badge badge-warning" style="font-size: 100%">WARN</span>
                  </td>
                </template>
                <template v-else-if="item.level === 'INFO'">
                  <td>
                    <span class="badge badge-success" style="font-size: 100%">INFO</span>
                  </td>
                </template>
                <template v-else-if="item.level === 'ERROR'">
                  <td>
                    <span class="badge badge-danger" style="font-size: 100%">ERROR</span>
                  </td>
                </template>
                <template v-else-if="item.level === 'FATAL'">
                  <td>
                    <span class="badge badge-secondary" style="font-size: 100%">FATAL</span>
                  </td>
                </template>
                <td style="text-align: right;">
                  <nav style="width: 120px; ">{{ item.timeStamp | moment("YYYY-MM-DD HH:mm") }}</nav>
                </td>
                <td>
                  <b-btn variant="primary" size="sm" @click="showModal(item)">{{ $t('message.detail') }}</b-btn>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <br />
        <b-pagination size="md" :total-rows="totalRows" v-model="pagination" :per-page="itemperPage"
          @input="pageChanged(pagination)"></b-pagination>
      </div>
    </b-card>

    <!-- for CLEAR MONEY EVENT -->
    <b-modal ref="detailModalMoney" hide-footer hide-header>
      <table class="table-modal" style="text-align: center">
        <thead style="text-align-last: center">
          <th>{{ $t('message.type') }}</th>
          <th>{{ $t('message.amount') }}</th>
          <th>{{ $t('message.balance') }}</th>
        </thead>
        <tbody>
          <tr>
            <th>{{ $t('message.coin') }}</th>
            <td></td>
            <td></td>
          </tr>
          <template v-for="(value, key) in modalData.moneyInboxServer.coin">
            <tr>
              <td>{{ key }} {{ $t('message.currency') }}</td>
              <td>{{ value }}</td>
              <td>{{ value * key }} {{ $t('message.currency') }}</td>
            </tr>
          </template>
          <tr>
            <th>{{ $t('message.banknote') }}</th>
            <td></td>
            <td></td>
          </tr>
          <template v-for="(value, key) in modalData.moneyInboxServer.bill">
            <tr>
              <td>{{ key }} {{ $t('message.currency') }}</td>
              <td>{{ value }}</td>
              <td>{{ value * key }} {{ $t('message.currency') }}</td>
            </tr>
          </template>
        </tbody>
        <tfoot style="text-align-last: center">
          <td></td>
          <th>{{ $t('message.sum') }}</th>
          <th>{{ modalData.moneyInboxServer.total }} {{ $t('message.currency') }}</th>
        </tfoot>
      </table>
    </b-modal>

    <b-modal ref="detailFillSlot" hide-footer hide-header size="lg">
      <table class="table-modal" style="text-align: center">
        <thead style="text-align-last: center">
          <th>{{ $t('message.slot') }}</th>
          <th>{{ $t('message.productId') }}</th>
          <th>{{ $t('message.product') }}</th>
          <th>{{ $t('message.amount') }}</th>
          <th>{{ $t('message.price') }}</th>
        </thead>
        <tbody>
          <template v-for="items in modalFillSlot">
            <tr>
              <td>{{ items.slotId }}</td>
              <td>{{ items.productId }}</td>
              <td>{{ items.name }}</td>
              <td>{{ items.qty }}</td>
              <td>{{ items.price }}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </b-modal>

    <b-modal ref="detailModalEmpty" hide-footer hide-header>
      <table class="table-modal" style="text-align: center">
        <thead style="text-align-last: center">
          <th>{{ $t('message.slot') }}</th>
          <th>{{ $t('message.status') }}</th>
        </thead>
        <tbody>
          <template v-for="items in modalDataEmpty">
            <tr>
              <td>{{ items.slot }}</td>
              <td>
                <span style="color: red">{{ $t('message.outOfStock') }}</span>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </b-modal>

    <b-modal ref="updateMachine" hide-footer hide-header size="lg">
      <!-- <vue-json-pretty :path="modalUpdate" :data="modalUpdate" @click="handleClick"></vue-json-pretty> -->
      <vue-json-pretty :data="modalUpdate"></vue-json-pretty>
    </b-modal>
  </div>
</template>
<script>
// import DatePicker from '../../custom_modules/vue2-datepicker'
import webServices from '../../script'
import moment from 'moment'
import VueJsonPretty from 'vue-json-pretty'

import DateTimePicker from '../../components/DateTimePicker/Custom.vue'
// import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import { ListSelect, ModelListSelect } from '../../custom_modules/search-select'

export default {

  components: {
    // DatePicker,
    DateTimePicker,
    VueJsonPretty,
    ListSelect,
    ModelListSelect
  },
  data() {
    return {
      pagination: 1,
      totalRows: 0,
      rowData: [],
      itemperPage: 20,
      searchData: {
        s_machineId: '',
        s_eventId: '',
        s_datetime: '',
        s_event: '',
        s_level: ''
      },
      fromdate: '',
      todate: '',
      events: [
        { value: '', text: 'All' },
        { value: 'INFO', text: 'INFO' },
        { value: 'WARN', text: 'WARN' },
        { value: 'ERROR', text: 'ERROR' },
        { value: 'FATAL', text: 'FATAL' }
      ],
      eventDefine: [{ id: '', event: '' }],
      modalData: {
        moneyInboxServer: {
          coin: '',
          bill: ''
        }
      },
      modalDataEmpty: '',
      modalFillSlot: '',
      modalUpdate: '',
      machineData: [],
      selectedMachine: [],
      machineId: '',
    }
  },
  mounted() {
    this.getMachine();

    this.getEventData(1, this.itemperPage, this.searchData.machineId, this.searchData.s_eventId, this.searchData.s_event, this.searchData.s_level, this.fromdate, this.todate)
    this.getEventDefine()
  },
  methods: {
    async getMachine() {
      this.$Progress.start()
      await this.axios
        .get(`/machines/list`)
        .then(res => {
          this.$Progress.finish()
          this.machineData = res.data
          if (this.machineData.length == 1) {
            this.selectedMachine = this.machineData
          }
        })
        .catch(err => {
          this.$Progress.fail()
          this.$toast.error({
            title: 'ERROR',
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
          })
          console.log('error @machine')
          console.log(err)
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },
    onSelectMachine(items) {
      // console.log(items)
      this.selectedMachine = items
      this.searchData.machineId = this.selectedMachine.map(data => data.machineId)
    },
    searchFn() {
      console.log('this.searchData.machineId', this.searchData.machineId)
      if (this.searchData.s_datetime !== '') {
        var timeEnd = this.searchData.s_datetime[1].toLocaleTimeString()
        if (timeEnd === '00:00:00') {
          this.searchData.s_datetime[1].setHours(23, 59, 59, 999)
        }
        this.fromdate = moment(this.searchData.s_datetime[0]).format('YYYY-MM-DD HH:mm:ss')
        this.todate = moment(this.searchData.s_datetime[1]).format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.fromdate = ''
        this.todate = ''
      }
      this.getEventData(1, this.itemperPage, this.searchData.machineId, this.searchData.s_eventId, this.searchData.s_event, this.searchData.s_level, this.fromdate, this.todate)
      this.pagination = 1
    },
    pageChanged(page) {
      window.scrollTo(0, 0)
      this.getEventData(page, this.itemperPage, this.searchData.machineId, this.searchData.s_eventId, this.searchData.s_event, this.searchData.s_level, this.fromdate, this.todate)
    },
    getEventData(page, row, machineid, eventid, event, level, from, to) {
      this.$Progress.start()
      webServices
        .getEvent(page, row, machineid, eventid, event, level, from, to)
        .then(res => {
          this.$Progress.finish()
          this.totalRows = res.data.count
          this.rowData = res.data.rows
          // console.log(res.data)
        })
        .catch(err => {
          // alert('หมดเวลาการเชื่อมต่อ กรุณาเข้าสู่ระบบใหม่อีกครั้ง')
          this.$Progress.fail()
          console.log('error @EVENT MACHINE')
          console.log(err)
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          }
          this.$toast.error({
            title: 'ERROR',
            message: JSON.stringify(errorText)
          })
        })
    },
    getEventDefine() {
      webServices
        .getEventDefine()
        .then(res => {
          // console.log(res.data)
          for (var i = 0; i < res.data.length; i++) {
            this.eventDefine.push(res.data[i])
          }
          // console.log(this.eventDefine)
        })
        .catch(err => {
          console.log(err)
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          }
          this.$toast.error({
            title: 'ERROR',
            message: JSON.stringify(errorText)
          })
        })
    },
    showModal(data) {
      console.log(data.value.data)

      if (data.event === 'CLEAR_MONEY') {
        this.modalData = data.value.data
        this.$refs.detailModalMoney.show()
      } else if (data.event === 'EMPTY_SLOT') {
        this.modalDataEmpty = data.value.data
        this.$refs.detailModalEmpty.show()
        console.log('EMPTY')
      } else if (data.event === 'FILL_SLOT' || data.event === 'FILL_SLOT_ALL') {
        this.modalFillSlot = data.value.data
        console.log(this.modalFillSlot)
        this.$refs.detailFillSlot.show()
        console.log('FILL SLOT')
      } else if (data.event === 'UPDATE_MACHINE') {
        this.modalUpdate = data.value.data
        this.$refs.updateMachine.show()
      }
    }
  }
}
</script>
